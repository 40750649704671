<template>
  <div class="mb-6">
    <div class="bg-gray-100 p-3 border-l-2 border-t-2 border-r-2 border-b-2 border-solid border-gray-300 rounded-t-lg rounded-b-lg overflow-hidden" >
      <transition name="fade" mode="out-in">
        <div v-if="!finished" key="form">
          <div class="mb-2">
            <a href="/subscription/new">
              <img src="https://img.patriotpost.us/01HSXNAWJXVJ2EMWC2K9NFSA7M.png">
            </a>
          </div>
          <p class="leading-tight mb-2">"<cite>The Patriot Post</cite> is the nation's leading digest of conservative news and opinion."</p>
          <div key="form" v-if="!finished">
            <div class="input-group">
              <input v-model="email" v-on:keyup="keyUp = true" type="text" placeholder="Email address">
              <button @click="clicked" class="text-sm bg-blue-900 hover:bg-gray-900">
                Subscribe
              </button>
            </div>
            <div style="margin-top: 10px;">
              <img src="https://img.patriotpost.us/01HSXVWXEQS9T9MB5YMQP782RE.png">
            </div>
          </div>
          <transition name="fade" mode="out-in">
            <div v-if="error" key="error" class="alert alert-error m-0 mt-3 px-3 py-2 text-sm">{{ error }}</div>
          </transition>
        </div>
        <div v-else key="success" class="alert alert-success mb-0">
          <strong>You've got mail!</strong> Check your inbox to activate your subscription.
        </div>
      </transition>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'request_referrer',
    'request_fullpath',
    'form_type'
  ],
  data: function() {
    return {
      keyUp: false,
      submitting: false,
      finished: false,
      email: null,
      error: null
    }
  },
  methods: {
    validateEmail: function() {
      var comp = this;
      if (!this.email || this.email.trim() == '') {
        comp.error = 'Please enter a valid email address.';
        return false;
      } else if (this.email && !/[\S]+@[\S]+\.[\S]+/.test(this.email.trim())) {
        comp.error = 'Please enter a valid email address.';
        return false;
      } else {
        var xmlHttp = new XMLHttpRequest();
        var url = 'https://emailvalidation.abstractapi.com/v1/?api_key=b7463b224a8743f596dc4a51778497c0&email=' + this.email;
        let emailResponse;
        xmlHttp.onreadystatechange = function() {
          if(this.readyState === 4) {
            if(this.status === 200) {
                emailResponse = JSON.parse(this.responseText);
                console.log(emailResponse.deliverability);
                if(emailResponse.deliverability === 'DELIVERABLE') {
                    comp.error = null;
                    return true;
                } else {
                    comp.error = 'Please enter a valid email address.';
                    return false;
                }
            } else {
                comp.error = 'Please enter a valid email address.';
                return false;
            }
          }
        }
        xmlHttp.open("GET", url, false); // true for asynchronous
        xmlHttp.send(null);

        if(comp.error !== null)
        {
            return false;
        } else {
            return true;
        }
      }
    },
    clicked: function(e) {
      this.error = null;
      if (!this.validateEmail()) {
        return
      }
      this.submitting = true
      this.axios.post('/api/subscriptions.json',
        {
          person: { email: this.email.trim() },
          subscription: {
            utm_source: this.utm_source,
            utm_medium: this.utm_medium,
            utm_campaign: this.utm_campaign,
            request_referrer: this.request_referrer,
            request_fullpath: this.request_fullpath,
            form_type: this.form_type
          }
        }
      ).then((response) => {
        this.submitting = false
        this.finished = true
        if (typeof(Storage) !== "undefined") {
          localStorage.setItem("user.subscriber", "true")
          localStorage.setItem("user.subscribed_at", Date.now())
        }
      }).catch((error) => {
        this.submitting = false
        if (400 == error.response.status) {
          this.error = error.response.data.errors
        } else if (409 == error.response.status && error.response.data.redirect_to) {
          window.location = error.response.data.redirect_to
        } else {
          console.log(error.response)
        }
      });
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .25s
}

.fade-enter, .fade-leave-to {
  opacity: 0
}
</style>