<template>
  <div>
    <div v-if="!finished">
      <form v-on:submit.prevent="onSubmit" id="subscribe" class="">
        <div class="mb-12 md:flex md:flex-row rounded-lg overflow-hidden border border-gray-400 shadow">
          <div class="col-md-2 banner-imgs-desktop">
            <img class="mt-8" src="https://img.patriotpost.us/01HSXYS3A3YKPDZG8QG8KBMZGB.png">
          </div>
          <div class="col-md-8">
            <div v-if="segment.feature_image_url" class="md:flex-shrink w-full md:w-5/12 lg:w-1/3">
              <img :src="segment.feature_image_url" class="segment-image block h-full w-full md:object-cover">
            </div>
            <div :class="segmentClasses">
              <h1 class="segment-title"><slot name="title"></slot></h1>
              <div class="segment-body"><slot name="body"></slot></div>
              <!-- Gifts -->
              <div v-if="showGifts">
                <div class="mb-4 premium-container md:p-1">
                  <label for="gift">
                    Select a free gift with your subscription
                  </label>
                  <div>
                    <option-select :value="subscription.gift" :blank="true" blank_value="declined" blank_text="Please don't send me anything" :options="segment.gifts" :error="error('subscription.gift')" id="gift" @change="handleGift">
                      Gift
                    </option-select>
                  </div>
                </div>
              </div>
              <!-- Snail mail address -->
              <transition name="fade">
                <div v-if="showMailingAddress">
                  <div>
                    <div class="flex flex-row flex-wrap">
                      <!-- First name -->
                      <div class="w-full md:w-1/2 mb-2 md:p-1">
                        <div :class="{ 'has-error': error('person.first_name') }">
                          <label class="mb-1" for="first_name">First name</label>
                          <input type="text" v-model="person.first_name" v-on:change="validateFirstName" id="first_name" class="">
                          <p v-if="error('person.first_name')" class="text-danger">{{ error('person.first_name') }}</p>
                        </div>
                      </div>
                      <!-- Last name -->
                      <div class="w-full md:w-1/2 mb-2 md:p-1">
                        <div :class="{ 'has-error': error('person.last_name') }">
                          <label class="mb-1" for="last_name">Last name</label>
                          <input type="text" v-model="person.last_name" v-on:change="validateLastName" id="last_name" class="">
                          <p v-if="error('person.last_name')" class="text-danger">{{ error('person.last_name') }}</p>
                        </div>
                      </div>
                    </div>

                    <div class="flex flex-row flex-wrap">
                      <!-- Street address -->
                      <div class="w-full mb-2 md:p-1">
                        <div :class="{ 'has-error': error('person.address_1') }">
                          <label class="mb-1" for="address_1">Street address</label>
                          <div class="row">
                            <div class="mb-2 address-line-1">
                              <input type="text" v-model="person.address_1" v-on:change="validateStreet" id="address_1" class="">
                            </div>
                            <div class="">
                              <input type="text" v-model="person.address_2" id="address_2" class="">
                            </div>
                          </div>
                          <p v-if="error('person.address_1')" class="text-danger">{{ error('person.address_1') }}</p>
                        </div>
                      </div>
                      <!-- City -->
                      <div class="w-full md:w-1/2 mb-2 md:p-1">
                        <div :class="{ 'has-error': error('person.city') }">
                          <label class="mb-1" for="city">City</label>
                          <input type="text" v-model="person.city" v-on:change="validateCity" id="city" class="">
                          <p v-if="error('person.city')" class="text-danger">{{ error('person.city') }}</p>
                        </div>
                      </div>

                      <!-- State -->
                      <div class="w-full md:w-1/2 mb-2 md:p-1">
                        <div :class="{ 'has-error': error('person.state') }">
                          <label class="mb-1" for="state">State</label>
                          <option-select :value="person.state" :blank="true" :options="form.states" :error="error('person.state')" @change="handleState" id="state">
                            State
                          </option-select>
                          <p v-if="error('person.state')" class="text-danger">{{ error('person.state') }}</p>
                        </div>
                      </div>
                      <div class="clearfix"></div>
                    </div>

                    <div class="flex flex-row flex-wrap">
                      <!-- Zip -->
                      <div class="w-full md:w-1/2 mb-2 md:p-1">
                        <div :class="{ 'has-error': error('person.zip') }">
                          <label class="mb-1" for="zip">Zip</label>
                          <input type="text" v-model="person.zip" v-on:change="validateZip" id="zip" class="">
                          <p v-if="error('person.zip')" class="text-danger">{{ error('person.zip') }}</p>
                        </div>
                      </div>

                      <!-- Country -->
                      <div class="w-full md:w-1/2 mb-2 md:p-1">
                        <div :class="{ 'has-error': error('person.country') }">
                          <label class="mb-1" for="country">Country</label>
                          <option-select :value="person.country" :blank="false" :options="form.countries" :error="error('person.country')" @change="handleCountry" id="country">
                            Country
                          </option-select>
                          <p v-if="error('person.country')" class="text-danger">{{ error('person.country') }}</p>
                        </div>
                      </div>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
              </transition>
              <!-- Email input -->
              <div class="mt-6" :class="{ 'has-error': error('person.email') }">
                <div>
                  <div class="input-group subscribe">
                    <input type="text" v-model="person.email" id="email" placeholder="Enter email address">
                    <button type="submit" :disabled="buttonDisabled">{{ buttonText }}</button>
                    <input type="hidden" name="tid" v-model="tid">
                  </div>
                  <p v-if="error('person.email')" class="text-danger">{{ error('person.email') }}</p>
                </div>
              </div>
              <!-- Privacy -->
              <div class="mb-12">
                <p class="mb-6 text-center" style="font-size:12px;">This site is protected by reCAPTCHA and the Google <a class="underline" href="https://policies.google.com/privacy">Privacy Policy</a> and <a class="underline" href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
                <p class="text-center" style="font-size:12px;">The Patriot Post does not exchange or sell any information about our users or subscribers to any third party under any circumstances, except as described above and in our <a class="underline" href="/about/privacy">Privacy Policy</a>. Subscribers agree to abide by The Patriot Post's <a class="underline" href="/about/terms">Terms of Use</a> and will receive occasional offers from The Patriot Post Shop in support of our Mission of Service to America's Armed Forces.</p>
              </div>
              <!-- reCAPTCHA -->
              <div class="form-actions">
                <vue-recaptcha
                  ref="invisibleRecaptcha"
                  sitekey="6LcNsGQUAAAAACPtOOcbnGEY5gT2opDNQ522s5BG"
                  size="invisible"
                  @verify="onVerify">
                </vue-recaptcha>
              </div>
              <div class="banner-imgs-mobile">
                <img class="mt-5" src="https://img.patriotpost.us/01HSXYHMA9JZM7CQ7MQKBYNS5X.png">
              </div>
            </div>
          </div>
          <div class="col-md-2 banner-imgs-desktop">
            <img class="mt-8" src="https://img.patriotpost.us/01HSY0T3826K0HCJW25V6ZYRWN.png">
          </div>
        </div>
      </form>
    </div>
    <div v-if="finished">
      <div class="mb-12 p-6 rounded-lg border border-gray-400 shadow">
        <slot name="success" v-bind:slotProps="{ email: person.email, contact_url: contact_url }"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import FormHelper from '../form_helper.js'
import OptionSelect from './option_select.vue'

let Every = require('lodash/every')
let Values = require('lodash/values')
let IsNull = require('lodash/isNull')
let PickBy = require('lodash/pickBy')

export default {
  components: {
    VueRecaptcha,
    OptionSelect
  },
  props: ['init'],
  data: function() {
    return {
      post_url: '/subscription.json',
      contact_url: null,
      submitting: false,
      finished: false,
      errors: {},
      person: null,
      subscription: null,
      segment: null,
      tid: null,
      form: {
        states: FormHelper.states,
        countries: FormHelper.countries
      }
    }
  },
  created: function() {
    let json = JSON.parse(this.init)
    this.subscription = json.subscription
    this.person = json.person
    this.segment = json.segment
    this.contact_url = json.contact_url
  },
  methods: {
    handleGift: function(gift) {
      this.subscription.gift = gift
    },
    handleState: function(state) {
      this.person.state = state
      this.validateState()
    },
    handleCountry: function(country) {
      this.person.country = country
      this.validateCountry()
    },
    onSubmit: function() {
      this.submitting = true;

      this.errors = {};

      this.validateEmail();

      if (this.postalAddressRequired) {
        this.validateFirstName();
        this.validateLastName();
        this.validateStreet();
        this.validateCity();
        this.validateState();
        this.validateZip();
        this.validateCountry();
      }

      if (this.hasErrors) {
        return this.submitFailed();
      } else {
        this.$refs.invisibleRecaptcha.execute();
      }
    },
    onVerify: function(response) {
      this.axios.post(this.post_url,
        {
          'g-recaptcha-response': response,
          segment: this.segment,
          person: PickBy(this.person, function(value) { return value != '' && value != null; }),
          subscription: this.subscription
        }
      ).then((response) => {
        this.tid = response.data.tid
        console.log(this.tid)
        if (this.segment.urban_legend) {
          try {
            let options = { mode: 'manual' }
            ULExc.invoke(options, this.tid)
          } catch(error) {}
        }

        if (typeof(Storage) !== "undefined") {
          localStorage.setItem("user.subscriber", "true");
          localStorage.setItem("user.subscribed_at", Date.now());
        }

        this.submitting = false;
        this.finished = true;

        this.$nextTick(function() {
          window.scrollTo(0,0);
        });
      }).catch((error) => {
        this.submitting = false;
        if (400 == error.response.status) {
          this.hasError('general', error.response.data.errors)
        } else if (409 == error.response.status && error.response.data.redirect_to) {
          window.location = error.response.data.redirect_to;
        } else {
          console.log(error.response);
        }
      });
    },
    submitFailed: function() {
      this.submitting = false;
      return false;
    },
    error: function(key) {
      return this.errors[key];
    },
    hasError: function(key, val) {
      this.$set(this.errors, key, val);
    },
    isValid: function(key) {
      this.$set(this.errors, key, null);
    },
    validateFirstName: function() {
      if (!this.person.first_name || this.person.first_name.trim() == '') {
        this.hasError('person.first_name', 'First name is required.');
      } else {
        this.isValid('person.first_name');
      }
    },
    validateLastName: function() {
      if (!this.person.last_name || this.person.last_name.trim() == '') {
        this.hasError('person.last_name', 'Last name is required.');
      } else {
        this.isValid('person.last_name');
      }
    },
    validateStreet: function() {
      if (!this.person.address_1 || this.person.address_1.trim() == '') {
        this.hasError('person.address_1', 'Street address is required.');
      } else {
        this.isValid('person.address_1');
      }
    },
    validateCity: function() {
      if (!this.person.city || this.person.city.trim() == '') {
        this.hasError('person.city', 'City or town is required.');
      } else {
        this.isValid('person.city');
      }
    },
    validateState: function() {
      if ((this.person.country && this.person.country == 'US') && (!this.person.state || this.person.state.trim() == '')) {
        this.hasError('person.state', 'State is required.');
      } else {
        this.isValid('person.state');
      }
    },
    validateZip: function() {
      if (!this.person.zip || this.person.zip.trim() == '') {
        this.hasError('person.zip', 'Zip or postal code is required.');
      } else {
        this.isValid('person.zip');
      }
    },
    validateCountry: function() {
      if (!this.person.country || this.person.country.trim() == '') {
        this.hasError('person.country', 'Country is required.');
      } else {
        this.isValid('person.country');
      }
    },
    validateEmail: function() {
      var comp = this;
      if (!this.person.email || this.person.email.trim() == '') {
        this.hasError('person.email', 'Email is required.');
      } else if (this.person.email && !/[\S]+@[\S]+\.[\S]+/.test(this.person.email.trim())) {
        this.hasError('person.email', 'Email must be valid.');
      } else {
        var xmlHttp = new XMLHttpRequest();
        var url = 'https://emailvalidation.abstractapi.com/v1/?api_key=b7463b224a8743f596dc4a51778497c0&email=' + this.person.email;
        let emailResponse;
        xmlHttp.onreadystatechange = function() {
          if(this.readyState === 4) {
            if(this.status === 200) {
                emailResponse = JSON.parse(this.responseText);
                console.log(emailResponse.deliverability);
                if(emailResponse.deliverability === 'DELIVERABLE') {
                    comp.isValid('person.email');
                } else {
                    comp.hasError('person.email', 'Email is Invalid.  Please Enter a new Email.');
                }
            } else {
                comp.hasError('person.email', 'Error Validating Your Email. Please Try Again.');
            }
          }
        }
        xmlHttp.open("GET", url, false); // true for asynchronous
        xmlHttp.send(null);
      }
    }
  },
  computed: {
    segmentClasses: function() {
      if (this.segment.feature_image_url) {
        return 'w-full p-6 md:w-7/12 lg:w-2/3 md:flex-shrink-0 md:self-center';
      } else {
        return 'w-full p-6';
      }
    },
    postalAddressRequired: function() {
      return this.giftSelected;
    },
    showGifts: function() {
      return this.segment.gifts && this.segment.gifts.length > 0;
    },
    giftSelected: function() {
      if (
        null == this.subscription.gift ||
        '' == this.subscription.gift ||
        'declined' == this.subscription.gift
      ) {
        return false;
      } else {
        return true;
      }
    },
    showMailingAddress: function() {
      return (this.showGifts && this.giftSelected);
    },

    hasErrors: function() {
      if (!Every(Values(this.errors), IsNull)) {
        return true;
      } else {
        return false;
      }
    },
    buttonText: function() {
      if (this.buttonDisabled) {
        return 'Submitting...';
      } else {
        return 'Subscribe'
      }
    },
    buttonDisabled: function() {
      return this.submitting;
    }
  },
  watch: {
    'subscription.digest': function() {
      if (this.subscription.digest) {
        this.subscription.daily_summary = false
      }
    },
    'subscription.daily_summary': function() {
      if (this.subscription.daily_summary) {
        this.subscription.digest = false
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}

.fade-enter, .fade-leave-to {
  opacity: 0
}

.text-danger {
  @apply
    m-1
    text-sm
    text-red-600;
}

.input-group.subscribe {
  min-height: 53px;
  @apply
    rounded-lg;
}

.input-group.subscribe button {
  @apply
    px-6
    text-xl
    bg-green-600;
}
.input-group.subscribe button:hover,
.input-group.subscribe button:focus {
  @apply
    bg-green-800;
}

.segment-title,
.pending-title {
  @apply
    text-4xl
    font-bold
    leading-none
    text-center
    mb-6;
}

.segment-title {
  @apply
    text-green-600;
}

.pending-title {
  @apply
    text-yellow-600;
}

.segment-body {
  @apply mb-8;
}

.segment-body p,
.pending-body p {
  @apply
    text-lg
    mb-3
    text-center
    text-gray-700;
}

.pending-body a {
  @apply
    text-yellow-600;
}

.banner-imgs-mobile {
    display: none;
}

@media only screen and (max-width: 768px)
{
    .banner-imgs-desktop {
        display: none;
    }
    .banner-imgs-mobile {
        display: initial;
    }
}
</style>