<template>
  <div>
    <template v-if="!finished">
      <div class="outer">
        <div class="header">
          <h1>Support The Patriot Fund<sup class="text-sm">&reg;</sup></h1>
          <p>Securing American Liberty for this and the next generation.</p>
        </div>
        <div class="inner">
          <section class="support-frequency">
            <div class="donate-btn-group">
              <frequency-button
                v-for="frequency in frequencies"
                :key="frequency.value"
                :value="frequency.value"
                :selected="donation.frequency"
                @click="handleFrequency">
                {{ frequency.label }}
              </frequency-button>
            </div>
          </section>
          <section class="support-levels">
            <div class="grid grid-cols-2 gap-x-2">
            <div v-if="twentyfour" class="col-span-2 mb-2">
              <amount-button
                value="24.00"
                :selected="preset_amount"
                :classes="{ 'mb-0': true }"
                @click="handlePresetAmount">
                $24 <span class="font-light">for 2024</span><br>
                <span class="font-normal text-sm uppercase"></span>
              </amount-button>
              </div>
              <div class="item">
                <label>Operation Support</label>
                <amount-button
                  v-for="amount in operation_amounts"
                  :key="amount"
                  :value="amount"
                  :selected="preset_amount"
                  @click="handlePresetAmount">
                </amount-button>
              </div>
              <div class="item">
                <label>Mission Support</label>
                <amount-button
                  v-for="amount in mission_amounts"
                  :key="amount"
                  :value="amount"
                  :selected="preset_amount"
                  @click="handlePresetAmount">
                </amount-button>
              </div>
              <div class="col-span-2">
                <amount-button
                  value="1776.00"
                  :selected="preset_amount"
                  :classes="{ 'mb-0': true }"
                  @click="handlePresetAmount">
                  $1,776 <span class="font-light">Day of Liberty Sponsor</span><br>
                  <span class="font-normal text-sm uppercase">1 day of our Mission Budget</span>
                </amount-button>
              </div>
            </div>
            <amount-input
              :value="other_amount"
              :error="getError('donation.amount')"
              @change="handleOtherAmount">
              Other Support Level
            </amount-input>
          </section>

          <transition name="fade">
            <gift-list
              v-if="showGiftList"
              :checkbox="gift_checkbox"
              :gifts="gifts"
              :selected="donation.gift"
              :error="getError('donation.gift')"
              @checkbox-click="handleGiftCheckbox"
              @button-click="handleGiftButton"
            >
            </gift-list>
          </transition>

          <section class="billing">
            <div class="columns">
              <div class="column">
                <text-input v-model="person.first_name" :error="getError('person.first_name')" @change="validateFirstName">
                  First Name
                </text-input>
              </div>

              <div class="column">
                <text-input v-model="person.last_name" :error="getError('person.last_name')" @change="validateLastName">
                  Last Name
                </text-input>
              </div>

              <div class="column">
                <text-input v-model="person.address_1" :error="getError('person.address_1')" @change="validateStreet">
                  Street Address
                </text-input>
              </div>

              <div class="column">
                <text-input v-model="person.address_2" labelClass="address_2"></text-input>
              </div>

              <div class="column">
                <text-input name="City" v-model="person.city" :error="getError('person.city')" @change="validateCity">
                  City
                </text-input>
              </div>

              <div class="column">
                <option-select id="state" :value="person.state" :options="states" :error="getError('person.state')" @change="handleState">
                  State
                </option-select>
              </div>

              <div class="column">
                <text-input v-model="person.zip" :error="getError('person.zip')" @change="validateZip">
                  ZIP Code
                </text-input>
              </div>

              <div class="column">
                <option-select :value="person.country" :options="countries" :error="getError('person.country')" @change="handleCountry">
                  Country
                </option-select>
              </div>

              <div class="column">
                <text-input v-model="person.email" :error="getError('person.email')" @change="validateEmail">
                  Subscriber Email
                </text-input>
              </div>

              <div class="column">
                <text-input v-model="person.email_confirmation" :error="getError('person.email_confirmation')" @change="validateEmailConf">
                  Confirm Subscriber Email
                </text-input>
              </div>

              <div class="column column-full">
                <div class="form-group">
                  <label for="card-input">Credit or Debit Card</label>
                  <div ref="cardInput" id="card-input" class="donate-input card text-xl"></div>
                  <p v-if="getError('card')" class="text-danger">{{ getError('card') }}</p>
                </div>
              </div>
            </div>
          </section>

          <div class="submit">
            <button @click="handleSubmit" :disabled="submitting" class="donate-btn submit" :class="submitButtonClass">{{ submitButtonText }}</button>
            <p v-if="hasErrors" class="text-danger">Please correct the errors above and try again.</p>
          </div>
        </div>
      </div>

      <div class="mb-6 p-3 bg-green-500 text-white rounded-lg">
        <div class="flex flex-row justify-center items-center">
          <div class="px-3 md:px-6">
            <a href="/donation/print">
              <svg style="min-width:48px;max-width:72px;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
            </a>
          </div>
          <div class="px-3 md:px-6">
            <p class="text-base md:text-lg"><strong>Prefer to donate by mail or fax?</strong></p>
            <p class="text-sm md:text-base"><a class="underline" href="/donation/print">Click here</a> for our printer-friendly donation form.</p>
          </div>
        </div>
      </div>
    </template>

    <template v-if="finished">
      <div class="outer">
        <div class="header">
          <h1>Thank You for Your Support!</h1>
        </div>
        <div class="inner">
          <p class="confirmation">A confirmation email will be sent to <strong>{{ person.email }}</strong>.</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
let Every = require('lodash/every')
let Values = require('lodash/values')
let IsNull = require('lodash/isNull')

import './donation_form.scss'
import FrequencyButton from './frequency_button.vue'
import AmountButton from './amount_button.vue'
import AmountInput from './amount_input.vue'
import GiftList from './gift_list.vue'
import TextInput from './text_input.vue'
import OptionSelect from './option_select.vue'
import FormHelper from '../form_helper.js'

export default {
  components: {
    FrequencyButton,
    AmountButton,
    AmountInput,
    GiftList,
    TextInput,
    OptionSelect
  },
  props: ['init'],
  data: function() {
    let json = JSON.parse(this.init)
    return {
      donation: null,
      person: null,
      gifts: null,
      url: null,
      stripe: null,
      card: null,
      frequencies: [
        { label: 'Give Once', value: null },
        { label: 'Annually',  value: 'Y' },
        { label: 'Monthly',   value: 'M' },
        { label: 'Quarterly', value: 'Q' }
      ],
      operation_amounts: [
        '30.00',
        '60.00',
        '120.00'
      ],
      mission_amounts: [
        '250.00',
        '500.00',
        '1000.00'
      ],
      preset_amount: null,
      other_amount: null,
      twentyfour: json.twentyfour,
      gift_checkbox: false,
      submitting: false,
      finished: false,
      states: FormHelper.states,
      countries: FormHelper.countries,
      errors: {
        'card': null,
        'general': null
      }
    }
  },
  created: function() {
    let json = JSON.parse(this.init)
    this.donation = json.donation
    this.person = json.person
    this.gifts = json.gifts
    this.url = json.url
    this.stripe = Stripe(json.stripe_public_key)

    if(json.twentyfour)
    {
        this.donation.amount = "24.00"
    }
  },
  mounted: function() {
    this.mountCardInput()
    let json = JSON.parse(this.init)
    if(json.twentyfour)
    {
        this.handlePresetAmount("24.00")
        this.submitButtonText()
    }
  },
  methods: {
    mountCardInput: function() {
      this.$nextTick(() => {
        let elements = this.stripe.elements()
        this.card = elements.create('card', {style: this.cardInputStyle(), hidePostalCode: true})
        this.card.mount(this.$refs.cardInput)
        this.card.addEventListener('change', (event) => {
          if (event.error) {
            this.setError('card', event.error.message)
          } else {
            this.isValid('card')
          }
        })
      })
    },
    cardInputStyle: function() {
      return {
        base: {
          color: '#333',
          fontSize: '16px'
        },
        invalid: {
          color: '#a94442',
          iconColor: '#a94442'
        }
      }
    },

    /////////////////////////
    // EVENT HANDLERS
    /////////////////////////

    handleSubmit: function(event) {
      event.preventDefault()
      this.submitting = true
      this.processForm()
    },
    processForm: function() {
      this.errors = {}

      this.validateRecurring()
      this.validateAmount()
      this.validateGift()
      this.validateFirstName()
      this.validateLastName()
      this.validateStreet()
      this.validateCity()
      this.validateState()
      this.validateZip()
      this.validateCountry()
      this.validateEmail()
      this.validateEmailConf()

      if (this.person.zip) {
        this.card.update({value: {postalCode: this.person.zip}})
      }

      this.createStripePayment()
    },
    createStripePayment: function() {
      this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card,
        billing_details: {
          name: `${this.person.first_name} ${this.person.last_name}`,
          email: this.person.email,
          address: {
            line1: this.person.address_1,
            line2: this.person.address_2,
            city: this.person.city,
            state: this.person.state,
            postal_code: this.person.zip,
            country: this.person.country
          }
        }
      }).then(this.handleStripePayment)
    },
    handleStripePayment: function(result) {
      if (result.error) {
        this.setError('card', result.error.message)
      }
      if (this.hasErrors) {
        this.submitting = false
        return
      } else {
        this.submitForm(result)
      }
    },
    submitForm: function(result) {
      this.axios.post(this.url, {
        donation: this.donation,
        person: this.person,
        payment_method_id: result.paymentMethod.id
      })
      .then((result) => {
        this.finished = true
        if (typeof(Storage) !== "undefined") {
          localStorage.setItem("user.donor", "true");
          localStorage.setItem("user.donated_at", Date.now());
        }
        this.$nextTick(() => {
          this.$root.$el.scrollIntoView()
        })
      })
      .catch((error) => {
        console.log(error.response)
        this.setError('card', error.response.data.error)
      })
      .then(() => {
        this.submitting = false
      })
    },
    handleFrequency: function(frequency) {
      if (frequency) {
        this.donation.recurring = true
        this.donation.frequency = frequency
      } else {
        this.donation.recurring = false
        this.donation.frequency = null
      }
    },
    handlePresetAmount: function(amount) {
      this.other_amount = null
      this.preset_amount = amount
      this.amountChanged(amount)
    },
    handleOtherAmount: function(amount) {
      this.preset_amount = null
      amount = amount.replace(/[^\d,\.]/g, '') // Remove anything that isn't a digit, comma, or decimal point
      this.other_amount = amount
      this.amountChanged(amount)
    },
    handleGiftCheckbox: function(checked) {
      this.gift_checkbox = checked
      if (!checked) {
        this.donation.gift = null
        this.validateGift()
      }
    },
    handleGiftButton: function(gift) {
      this.donation.gift = gift.name
      this.validateGift()
    },
    amountChanged: function(amount) {
      amount = amount.replace(/[^\d\.]/g, '') // Remove anything that isn't a digit or decimal point
      this.donation.amount = amount
      this.validateAmount()
      if (parseInt(this.donation.amount) < 30) {
        this.gift_checkbox = false
        this.donation.gift = null
      }
    },
    handleState: function(state) {
      this.person.state = state
      this.validateState()
    },
    handleCountry: function(country) {
      this.person.country = country
      this.validateCountry()
    },

    /////////////////////////
    // ERROR HANDLING
    /////////////////////////

    getError: function(key) {
      return this.errors[key]
    },
    isValid: function(key) {
      this.$set(this.errors, key, null)
    },
    setError: function(key, val) {
      this.$set(this.errors, key, val)
    },

    /////////////////////////
    // VALIDATIONS
    /////////////////////////

    validateRecurring: function() {
      if (this.donation.recurring == true && this.donation.frequency == 'select-interval') {
        this.setError('donation.recurring', 'Recurring interval is required.')
      } else {
        this.isValid('donation.recurring')
      }
    },
    validateAmount: function() {
      if (!this.donation.amount || this.donation.amount == '') {
        this.setError('donation.amount', 'Amount is required.')
      } else if (!/^\d{1,}\.\d{2}$/.exec(this.donation.amount)) {
        this.setError('donation.amount', 'Amount must be a number with two decimal places (e.g. "56.78").')
      } else if (parseInt(this.donation.amount) < 1) {
        this.setError('donation.amount', 'Amount must be at least $1.00.')
      } else {
        this.isValid('donation.amount')
      }
    },
    validateGift: function() {
      if (true == this.gift_checkbox && null == this.donation.gift) {
        this.setError('donation.gift', 'Please select a free gift.')
      } else {
        this.isValid('donation.gift')
      }
    },
    validateFirstName: function() {
      if (!this.person.first_name || this.person.first_name.trim() == '') {
        this.setError('person.first_name', 'First name is required.')
      } else {
        this.isValid('person.first_name')
      }
    },
    validateLastName: function() {
      if (!this.person.last_name || this.person.last_name.trim() == '') {
        this.setError('person.last_name', 'Last name is required.')
      } else {
        this.isValid('person.last_name')
      }
    },
    validateStreet: function() {
      if (!this.person.address_1 || this.person.address_1.trim() == '') {
        this.setError('person.address_1', 'Street address is required.')
      } else {
        this.isValid('person.address_1')
      }
    },
    validateCity: function() {
      if (!this.person.city || this.person.city.trim() == '') {
        this.setError('person.city', 'City or town is required.')
      } else {
        this.isValid('person.city')
      }
    },
    validateState: function() {
      if ((this.person.country && this.person.country == 'US') && (!this.person.state || this.person.state.trim() == '')) {
        this.setError('person.state', 'State is required.')
      } else {
        this.isValid('person.state')
      }
    },
    validateZip: function() {
      if (!this.person.zip || this.person.zip.trim() == '') {
        this.setError('person.zip', 'ZIP or postal code is required.')
      } else {
        this.isValid('person.zip')
      }
    },
    validateCountry: function() {
      if (!this.person.country || this.person.country.trim() == '') {
        this.setError('person.country', 'Country is required.')
      } else {
        this.isValid('person.country')
      }
    },
    validateEmail: function() {
      if (!this.person.email || this.person.email.trim() == '') {
        this.setError('person.email', 'Email is required.')
      } else if (this.person.email && !/\S+@\S+/.test(this.person.email.trim())) {
        this.setError('person.email', 'Email must be valid.')
      } else {
        this.isValid('person.email')
      }
    },
    validateEmailConf: function() {
      if (!this.person.email_confirmation || this.person.email_confirmation.trim() == '') {
        this.setError('person.email_confirmation', 'Email confirmation is required.')
      } else if (this.person.email_confirmation && this.person.email && this.person.email_confirmation.trim() != this.person.email.trim()) {
        this.setError('person.email_confirmation', 'Email must match confirmation.')
      } else {
        this.isValid('person.email_confirmation')
      }
    }
  },
  computed: {
    hasErrors: function() {
      if (!Every(Values(this.errors), IsNull)) {
        return true
      } else {
        return false
      }
    },
    showGiftList: function() {
      return 30 <= parseInt(this.donation.amount)
    },
    showGiftOptions: function() {
      return this.showGiftContainer && this.gift_checkbox
    },
    submitButtonText: function() {
      if (this.submitting) {
        return 'Submitting...'
      }
      if (this.donation.amount) {
        let amount = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.donation.amount)
        return `Give ${amount}`
      }
      return 'Submit'
    },
    submitButtonClass: function() {
      if (this.submitting) {
        return 'disabled'
      }
      if (this.hasErrors) {
       return 'error'
      }
      return 'primary'
    }
  },
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>